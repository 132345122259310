import React from "react";

import "../css/button-contact.css";
const mail = '../ressources/mail.svg';

function contactClick() {
    window.location.href = '/kontakt';
}

function ContactButton() {
  return (
    <button className="contact-button-small" onClick={contactClick}>
        <img src={mail}>
        </img>
    </button>
  );
}

export default ContactButton;
