import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import Header from './components/Header';
import './App.css';
import Kontakt from './pages/kontakt';
import Index from './pages';
import Footer from './components/footer';
import Datenschutz from './pages/datenschutz';
import Impressum from './pages/impressum';
import Ressourcen from './pages/ressourcen';
import NotFound from './pages/404';
import ScrollToTop from "./components/ScrollToTop";
import ContactButtonSmall from './components/ContactButton-small';

function App() {

  return (
    <Router>
            <ScrollToTop />
      <Header />
            <Routes>
                <Route exact path="/" element={<Index/>} />
                <Route path="/kontakt" element={<Kontakt />} />
                <Route path="/impressum" element={<Impressum />} />
                <Route path="/datenschutz" element={<Datenschutz />} />
                <Route path="/ressourcen" element={<Ressourcen />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
      <Footer/>
    </Router>
  );
}

export default App;