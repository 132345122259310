import React from "react";

import "../css/button-contact.css";

function contactClick(){
    window.location.href = "/kontakt";
}

function servicesClick(){
    const targetElement = document.querySelector("#service-section");
    if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' });
    }
}

function ContactButtonRow(){
    return (
        <div className="contact-button-row">
        <button className="contact-button-medium" onClick={contactClick}>
            <p>Kontakt</p>
        </button>
        <button className="contact-button-medium is-outlined has-icon" onClick={servicesClick}>
            <p>Services</p>
            <img src="/ressources/arrow_forward_ios.svg" alt="arrow_forward_ios" />
        </button>
        </div>
    );
}

export default ContactButtonRow;